import { MouseEventHandler, useState, useEffect } from "react";

// hooks
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useWeb3React } from "@web3-react/core";
import windowSize from "src/hooks/useWindowSize";

// utils
import { shorter, TokenType } from "src/utils";
import StandardTokenTestABI from "src/utils/abis/DifinesTokenTest.json";
import StandardTokenMainABI from "src/utils/abis/DifinesTokenMain.json";

// components
import Modal from "src/components/Modal";
import Button from "src/components/Button";
import Loader from "src/components/Loader";

// third party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toast from "react-hot-toast";

let standardABI = StandardTokenMainABI.abi;
let standardBytecode = StandardTokenMainABI.bytecode;

interface ParentProps {
  isShow: Boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
}
interface TokenInfoProps {
  tokenName: string;
  tokenSymbol: string;
  tokenSupply: number;
  tokenDecimal: number;
  tokenBurnable: boolean;
}

const BepToken = ({ isShow, onClose }: ParentProps) => {
  const validationSchema = Yup.object().shape({
    tokenName: Yup.string().required("Token name is required"),
    tokenSymbol: Yup.string().required("Token symbol is required"),
    tokenSupply: Yup.number()
      .required("Token supply is required")
      .min(1, "Token supply must be bigger than at least 1"),
    tokenDecimal: Yup.number().required("Token Decimal is required"),
  });
  const initialState: TokenInfoProps = {
    tokenName: "",
    tokenSymbol: "",
    tokenSupply: 0,
    tokenDecimal: 18,
    tokenBurnable: true,
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TokenInfoProps>({
    resolver: yupResolver(validationSchema),
  });
  const [tokenInfo, setTokenInfo] = useState<TokenInfoProps>({
    ...initialState,
  });
  const [deployAddr, setDeployAddr] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { account, chainId } = useWeb3React();
  const { width } = windowSize();
  const Network = {
    1: { name: "Ethereum Mainnet", url: "https://etherscan.io/token/" },
    3: { name: "Ropsten Testnet", url: "https://etherscan.io/token/" },
    4: { name: "Rinkeby Testnet", url: "https://etherscan.io/token/" },
    5: { name: "Goerli Testnet", url: "https://goerli.etherscan.io/token/" },
    56: { name: "BSC Mainnet", url: "https://bscscan.com/token/" },
    97: { name: "BSC Testnet", url: "https://testnet.bscscan.com/token/" },
    421614: {
      name: "Arbitrum Sepolia",
      url: "https://sepolia.arbiscan.io/token/",
    },
  };
  const AbiInfo = {
    56: {
      abi: StandardTokenMainABI.abi,
      bytecode: StandardTokenMainABI.bytecode,
    },
    97: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenMainABI.bytecode,
    },
    1: {
      abi: StandardTokenMainABI.abi,
      bytecode: StandardTokenMainABI.bytecode,
    },
    5: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    421614: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
  };

  useEffect(() => {
    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  useEffect(() => {
    if (chainId) {
      standardABI = AbiInfo[chainId]["abi"];
      standardBytecode = AbiInfo[chainId]["bytecode"];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account]);

  function onChange(e: { target: { name: any; value: any } }) {
    const { name, value } = e.target;
    if (name === "tokenBurnable")
      setTokenInfo({
        ...tokenInfo,
        [name]: !tokenInfo.tokenBurnable,
      });
    else
      setTokenInfo({
        ...tokenInfo,
        [name]: value,
      });
  }
  async function onSubmit(data: TokenInfoProps) {
    if (!account) {
      toast.error("Connect your metamask to mint");
      return;
    }
    setIsLoading(true);
    const web3 = new Web3(Web3.givenProvider);
    const standardTokenContract = new web3.eth.Contract(
      standardABI as AbiItem[]
    );
    await standardTokenContract
      .deploy({
        data: standardBytecode,
        arguments: [
          data.tokenSupply,
          data.tokenName,
          data.tokenDecimal,
          data.tokenSymbol,
        ],
      })
      .send(
        {
          from: account,
        },
        function (error, transactionHash) {
          if (error) {
            console.error(error);
            return;
          }
          console.log("Transaction Hash :", transactionHash);
        }
      )
      .on("confirmation", function () {
        return;
      })
      .then(function (newContractInstance) {
        if (!newContractInstance.options.address) {
          console.log(newContractInstance);
          return;
        }
        console.log(
          "Deployed Contract Address : ",
          newContractInstance.options.address
        );
        setDeployAddr(newContractInstance.options.address);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        setIsLoading(false);
      });
  }
  function onReset() {
    setTokenInfo(initialState);
    reset();
  }

  return (
    <Modal isOpen={isShow} className="creator-modal" onClose={onClose}>
      <div className={`overlay ${isLoading ? "show-overlay" : "hide-overlay"}`}>
        <Loader />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="heading">Create Your {TokenType[chainId]} Token</h1>
        {deployAddr && !isLoading && chainId ? (
          <div className="content">
            <h2 className="heading">Successfully deployed!</h2>
            <a
              href={Network[chainId]["url"] + deployAddr}
              className="heading"
              target="_blank"
              rel="noreferrer"
            >
              <span>Click here to check your token</span>
            </a>
          </div>
        ) : (
          <>
            <div className="content">
              <div className="row">
                <label htmlFor="">Selected Account *</label>
                <span>
                  {account
                    ? width < 640
                      ? shorter(account)
                      : account
                    : "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Current Network *</label>
                <span>
                  {chainId
                    ? Network[chainId]["name"]
                    : "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Name *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.tokenName}
                    {...register("tokenName")}
                    name="tokenName"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenName?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Symbol *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.tokenSymbol}
                    {...register("tokenSymbol")}
                    name="tokenSymbol"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenSymbol?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Initial Supply *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tokenSupply}
                    {...register("tokenSupply")}
                    name="tokenSupply"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenSupply?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Decimals *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tokenDecimal}
                    {...register("tokenDecimal")}
                    name="tokenDecimal"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenDecimal?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Burnable</label>
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={tokenInfo.tokenBurnable}
                    {...register("tokenBurnable")}
                    name="tokenBurnable"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="footer">
              <Button variant="primary" submit>
                Mint
              </Button>
              <Button variant="fill" onClick={onReset}>
                Reset
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default BepToken;
