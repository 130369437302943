// hooks
import { useWeb3React } from "@web3-react/core";
import { useModalShow } from "src/hooks/useModalShow";

// utils
import { TokenType } from "src/utils";

// components
import Container from "src/components/Container";
import Card from "src/components/Card";
import Button from "src/components/Button";
import BepToken from "./BepToken";

const Download = () => {
  const { chainId } = useWeb3React();
  const { isShow, getBepToken, closeModal } = useModalShow();

  return (
    <>
      <div className="ld-download">
        <Container className="nav-container">
          <Card className="download-card">
            <div className="nav-card">
              <div className="elementor-background-overlay"></div>
              <div className="content">
                <div className="left">
                  <div className="heading">
                    <span className="beginning_text">
                      Create Your Own {TokenType[chainId]} Token
                    </span>
                    <span className="underline--magical"> Now</span>
                  </div>
                  <div className="content">
                    Enjoy with our services of DIFINES.
                  </div>
                </div>
                <div className="right">
                  <Button
                    className="download-btn"
                    variant="primary"
                    onClick={getBepToken}
                  >
                    GET {TokenType[chainId]} TOKEN
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Container>
      </div>
      <BepToken isShow={isShow} onClose={closeModal} />
    </>
  );
};

export default Download;
