import { useState } from "react";

export function useModalShow() {
  const [isShow, setIsShow] = useState<Boolean>(false);

  function getBepToken() {
    setIsShow(true);
  }
  function closeModal() {
    setIsShow(false);
  }

  return { isShow, getBepToken, closeModal };
}
