const WhiteSaleWarn = () => {
  return (
    <div className="ps-warn">
      <div className="container">
        <p>
          Disclaimer: The information provided shall not in any way constitute a
          recommendation as to whether you should invest in any product
          discussed. We accept no liability for any loss occasioned to any
          person acting or refraining from action as a result of any material
          provided or published.
        </p>
      </div>
    </div>
  );
};

export default WhiteSaleWarn;
