// utils
import { fadeInUp, fadeInRight } from "src/utils/keyframes";

// third party
import Reveal from "react-awesome-reveal";

// components
import Container from "src/components/Container";
import Card from "src/components/Card";

const Comment = () => {
  return (
    <div className="ld-comment">
      <Container className="nav-container">
        <div className="full">
          <div className="top">
            <Reveal keyframes={fadeInUp} delay={100} triggerOnce>
              <div className="heading">Everyone's voice</div>
            </Reveal>
            <Reveal keyframes={fadeInUp} delay={200} triggerOnce>
              <div className="content">What people say</div>
            </Reveal>
            <Reveal keyframes={fadeInUp} delay={300} triggerOnce>
              <div className="desc">
                Thanks for all the comments about DIFINES'BEP20 token
                development service!
              </div>
            </Reveal>
          </div>
          <div className="down">
            <Reveal keyframes={fadeInRight} delay={200} triggerOnce>
              <div className="out-card">
                <Card className="secure">
                  <div className="star">
                    <div className="elementor-star-rating" title="5/5">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>{" "}
                    </div>
                  </div>
                  <div className="content">
                    I was surprised that the BEP20 token was created with just
                    one click. Moreover, it was completed for 100 $.
                  </div>
                  <div className="user">
                    <div className="name">
                      <div className="nick">Matsushima</div>
                      <div className="position">User</div>
                    </div>
                    <div className="avatar">
                      <img
                        src="https://toka.b-cdn.net/wp-content/uploads/2022/02/98-testimonial.png"
                        className="attachment-large size-large"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Reveal>
            <Reveal keyframes={fadeInRight} delay={400} triggerOnce>
              <div className="out-card">
                <Card className="trading">
                  <div className="star">
                    <div className="elementor-star-rating" title="5/5">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>{" "}
                    </div>
                  </div>
                  <div className="content">
                    We are in an era where anyone can easily issue BEP20 tokens.
                    This is great.
                  </div>
                  <div className="user">
                    <div className="name">
                      <div className="nick">Romana Mollika</div>
                      <div className="position">DIFINES partners</div>
                    </div>
                    <div className="avatar">
                      <img
                        src="https://toka.b-cdn.net/wp-content/uploads/2022/02/45-testimonial.png"
                        className="attachment-large size-large"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Reveal>
            <Reveal keyframes={fadeInRight} delay={600} triggerOnce>
              <div className="out-card">
                <Card className="auto secure">
                  <div className="star">
                    <div className="elementor-star-rating" title="5/5">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>{" "}
                    </div>
                  </div>
                  <div className="content">
                    It also comes with a Burn setting, which will help increase
                    the value of your tokens.
                  </div>
                  <div className="user">
                    <div className="name">
                      <div className="nick">S.James</div>
                      <div className="position">Project manager</div>
                    </div>
                    <div className="avatar">
                      <img
                        src="https://toka.b-cdn.net/wp-content/uploads/2022/02/100-testimonial.png"
                        className="attachment-large size-large"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Reveal>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Comment;
