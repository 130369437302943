// third party
import { Link } from "react-router-dom";
import Reveal from "react-awesome-reveal";

// hooks
import { useWeb3React } from "@web3-react/core";

// utils
import { TokenType } from "src/utils";
import { fadeInUp, fadeInLeft, fadeInRight } from "src/utils/keyframes";

// components
import Container from "src/components/Container";
import Card from "src/components/Card";

const Guide = () => {
  const { chainId } = useWeb3React();

  return (
    <div className="ld-guide" id="guide">
      <Container className="nav-container">
        <Reveal keyframes={fadeInUp} triggerOnce>
          <div className="top">
            <div className="left">
              <div className="heading">Learn crypto </div>
              <div className="content">Watch the Difines trading guide </div>
            </div>
            <div className="right">
              <div className="content">Let's make your original token now!</div>
              <div className="link">
                <Link to="#">
                  <span className="title">View all guides</span>
                  <span className="icon">
                    <i
                      aria-hidden="true"
                      className="fas fa-long-arrow-alt-right"
                    ></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </Reveal>
        <div className="down">
          <div className="card-group">
            <div className="video">
              <Reveal keyframes={fadeInLeft} triggerOnce>
                <div className="image">
                  <div className="play">
                    <a
                      href="//www.youtube.com/watch?v=https://www.youtube.com/watch?v=YBTdDfRmcrI"
                      data-lity=""
                    >
                      <span className="video-button">
                        <i className="fas fa-play"> </i>
                      </span>
                    </a>
                  </div>
                </div>
              </Reveal>
            </div>
            <div className="steps">
              <div className="second">
                <Reveal keyframes={fadeInRight} delay={100} triggerOnce>
                  <Card className="top">
                    <div className="nav-card">
                      <div className="heading">Step 1</div>
                      <div className="content">
                        Please connect to MetaMask with "Connect wallet" at the
                        top right of the site. 0.02BNB or more is required for
                        MetaMask.
                      </div>
                    </div>
                  </Card>
                </Reveal>
                <Reveal keyframes={fadeInRight} delay={200} triggerOnce>
                  <Card className="down">
                    <div className="nav-card">
                      <div className="heading">Step 2</div>
                      <div className="content">
                        Select "Get {TokenType[chainId]} Token" in the site and
                        enter it in "Name", "Symbol", "Initial Supply", and
                        "Decimals". Also, select "Burnable" if necessary.
                      </div>
                    </div>
                  </Card>
                </Reveal>
              </div>
              <div className="third">
                <Reveal keyframes={fadeInRight} delay={300} triggerOnce>
                  <Card className="top">
                    <div className="nav-card">
                      <div className="heading">Step 3</div>
                      <div className="content">
                        After entering the token information, select "Mint" and
                        MetaMask will start, so select Confirm.
                      </div>
                    </div>
                  </Card>
                </Reveal>
                <Reveal keyframes={fadeInRight} delay={400} triggerOnce>
                  <Card className="down">
                    <div className="nav-card">
                      <div className="heading">Step 4</div>
                      <div className="content">
                        Congratulations! The {TokenType[chainId]} token has been
                        successfully created. Select "Click here to check
                        transaction" to check the contract address.
                      </div>
                    </div>
                  </Card>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Guide;
