import { HashLink as Link } from "react-router-hash-link";
import Container from "src/components/Container";

const Footer = () => {
  return (
    <div className="ld-footer">
      <Container className="nav-container">
        <div className="link-group">
          <div className="sidebar">
            <Link to="/#about" smooth>
              About
            </Link>
            <Link to="/#portfolio" smooth>
              Portfolio
            </Link>
            <Link to="/#investing" smooth>
              Investing
            </Link>
            <Link to="/#guide" smooth>
              Guide
            </Link>
            <Link to="/whitesale">White Sale</Link>
          </div>
        </div>
        <div className="social-group">
          <div className="icon">
            <img src="/statistic/assets/icons/discord.svg" alt="discord" />
          </div>
          <div className="icon">
            <img src="/statistic/assets/icons/telegram.svg" alt="telegram" />
          </div>
          <div className="icon">
            <img src="/statistic/assets/icons/youtube.svg" alt="youtube" />
          </div>
        </div>
        <div className="copyright">
          <h4>DIFINES @ 2022</h4>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
