// components
import Button from "src/components/Button";
import Card from "src/components/Card";

const TokenHome = () => {
  return (
    <div className="ps-home">
      <div className="container">
        <div className="section-hero">
          <h1 className="hero-title">The WhiteSale Platform For Everyone!</h1>
          <p className="hero-desc">
            WhiteSale helps everyone to create their own tokens and token sales
            in few seconds. Tokens created on WhiteSale will be verified and
            published on explorer websites.
          </p>
          <div className="hero-button-group">
            <Button className="upload-btn" variant="primary">
              List now
            </Button>
            <Button className="learn-btn" variant="outlinePrimary">
              Learn more
            </Button>
          </div>
          <div className="hero-statistic">
            <Card className="card-component">
              <div className="nav-card">
                <h1>$100,000</h1>
                <p>Total Liquidity Amount</p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>$100,000</h1>
                <p>Total Liquidity Amount</p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>$100,000</h1>
                <p>Total Liquidity Amount</p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>$100,000</h1>
                <p>Total Liquidity Amount</p>
              </div>
            </Card>
          </div>
        </div>
        <div className="section-intro">
          <h1 className="hero-title">A Suite of Tools for Token Sales.</h1>
          <p className="hero-desc">
            A suite of tools were built to help you create your own tokens and
            launchpads in a fast, simple and cheap way, with no prior code
            knowledge required and 100% decentralized!
          </p>
          <div className="hero-statistic">
            <Card className="card-component">
              <div className="nav-card">
                <h1>Standard</h1>
                <p>
                  Mint standard tokens on Binance Smart Chain including testnet.
                </p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Deflation</h1>
                <p>
                  Generate deflationary tokens with tax and/or charity
                  functions.
                </p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Custome</h1>
                <p>Create a token sale for your own custom token easily.</p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Launchpad</h1>
                <p>
                  Use the token you mint to create a launchpad with just a few
                  clicks
                </p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Branding</h1>
                <p>
                  Adding logo, social links, description, listing on PinkSale.
                </p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Manage</h1>
                <p>
                  The portal to help you easily update content for your
                  launchpad.
                </p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Community</h1>
                <p>
                  Promote your launchpad to thousands of buyers on PinkSale.
                </p>
              </div>
            </Card>
            <Card className="card-component">
              <div className="nav-card">
                <h1>Locking</h1>
                <p>
                  Lock your liquidity to PinkSwap, PancakeSwap after presale.
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenHome;
