import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// third party
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// utils
import { ERC20TokenInfo, getProvider } from "src/utils";
import SaleContractABI from "src/utils/abis/WhiteSale.json";
import ERC20ContractABI from "src/utils/abis/ERC20.json";

// hooks
import { useWeb3React } from "@web3-react/core";
import useMulticallContract from "src/hooks/useMulticallContract";
import useSaleClient from "src/hooks/useSaleClient";
import useSaleAdmin from "src/hooks/useSaleAdmin";
import { utils } from "ethers";

// firebase
import { db } from "src/utils/firebase";
import { doc, getDoc, onSnapshot, DocumentData } from "firebase/firestore";

// components
import PreviewToken from "./white-sale-components/PreviewToken";
import ProgressBarComponent from "src/components/ProgressBar";
import Button from "src/components/Button";
import CountTimer from "./white-sale-components/CountTimer";

const PresaleDetails = () => {
  const { chainId, account } = useWeb3React();
  const { buyWithERC20, isLoading } = useSaleClient();
  const {
    startSaleByAdmin,
    finishSaleWithERC20,
    cancelSaleWithERC20,
    isLoadingAdmin,
  } = useSaleAdmin();
  const { getMulticallResults } = useMulticallContract();
  const location = useLocation();
  const [tokenInfo, setTokenInfo] = useState<DocumentData>({});
  const [selfContribute, setSelfContribute] = useState<string>("0");
  const [buyAmount, setBuyAmount] = useState<number>(0);
  const [selfBuy, setSelfBuy] = useState<boolean>(false);
  const [isStart, setIsStart] = useState<boolean>(false);
  const [userBalance, setUserBalance] = useState<string>("0");

  function handleBuyInput(evt: { target: { name: any; value: any } }) {
    const { value } = evt.target;
    setBuyAmount(value);
  }

  async function onHandleBuyToken() {
    if (
      buyAmount <= Number(tokenInfo.maxiBuy) &&
      buyAmount >= Number(tokenInfo.miniBuy)
    ) {
      await buyWithERC20(buyAmount, tokenInfo.currency, tokenInfo.saleAddress);
      setBuyAmount(0);
      setSelfBuy(!selfBuy);
    }
  }
  async function onHandleFinalize() {
    await finishSaleWithERC20(tokenInfo.saleAddress, tokenInfo.currency);
  }
  async function onHandleCancel() {
    await cancelSaleWithERC20(tokenInfo.saleAddress, tokenInfo.currency);
  }
  async function startSale() {
    await startSaleByAdmin(tokenInfo);
  }

  useEffect(() => {
    async function saleInit() {
      let saleId = location.pathname.split("/")[3]; // sale contract address
      onSnapshot(doc(db, "sales", saleId), (snapshot) => {
        if (snapshot.exists()) setTokenInfo(snapshot.data());
      });
    }
    saleInit();

    return () => {
      setTokenInfo({});
    };
  }, [location.pathname, chainId, selfBuy, account]);

  // check sale status
  useEffect(() => {
    async function checkSaleStatus() {
      if (!account) return;
      let saleId = location.pathname.split("/")[3]; // sale contract address
      const docRef = doc(db, "sales", location.pathname.split("/")[3]);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().saleStatus === 0) {
        alert("Start your sale!");
        setIsStart(true);
      }
      if (account && saleId && chainId) {
        if (docSnap.data().currency === "BNB") {
          // get contributors && user BNB balance
          let callData = [
            {
              reference: "WhiteSale",
              contractAddress: saleId,
              abi: SaleContractABI.abi,
              calls: [
                {
                  reference: "getBuyerInfo",
                  methodName: "getBuyerInfo",
                  methodParameters: [account],
                },
              ],
            },
          ];
          const contractCallResults = await getMulticallResults(callData);
          setSelfContribute(
            utils.formatEther(
              contractCallResults.results.WhiteSale.callsReturnContext[0]
                .returnValues[1]
            )
          );
          let provider = await getProvider();
          setUserBalance(utils.formatEther(await provider.getBalance(account)));
        } else {
          // get contributors && user erc20 balance
          let callData = [
            {
              reference: "WhiteSale",
              contractAddress: saleId,
              abi: SaleContractABI.abi,
              calls: [
                {
                  reference: "getBuyerInfo",
                  methodName: "getBuyerInfo",
                  methodParameters: [account],
                },
              ],
            },
            {
              reference: "ERC20Contract",
              contractAddress: ERC20TokenInfo[docSnap.data().currency][chainId],
              abi: ERC20ContractABI,
              calls: [
                {
                  reference: "balanceOf",
                  methodName: "balanceOf",
                  methodParameters: [account],
                },
              ],
            },
          ];
          const contractCallResults = await getMulticallResults(callData);
          setUserBalance(
            utils.formatEther(
              contractCallResults.results.ERC20Contract.callsReturnContext[0]
                .returnValues[0].hex
            )
          );
          setSelfContribute(
            utils.formatEther(
              contractCallResults.results.WhiteSale.callsReturnContext[0]
                .returnValues[1]
            )
          );
        }
      }
    }
    checkSaleStatus();

    return () => {
      setIsStart(false);
      setSelfContribute("0");
    };
  }, [account, chainId, location.pathname, selfBuy]);

  return (
    <div className="ps-detail">
      <div className="container">
        <div className="left">
          {Object.keys(tokenInfo).length > 0 ? (
            <PreviewToken details={tokenInfo} />
          ) : (
            <>
              <Skeleton height="100px" />
              <Skeleton count={20} />
            </>
          )}
        </div>
        <div className="right">
          {tokenInfo.ownerAddress === account ? (
            <div className="owner-box">
              <div className="nav-box">
                <div className="title">Onwer Action</div>
                {isStart ? (
                  <Button variant="primary" onClick={startSale}>
                    Start Sale
                  </Button>
                ) : (
                  <></>
                )}
                {moment.utc().format("X") > tokenInfo.secEndTime &&
                tokenInfo.totalRaised >= Number(tokenInfo.softcap) &&
                tokenInfo.saleStatus === 1 ? (
                  isLoadingAdmin ? (
                    <span>...loading</span>
                  ) : (
                    <Button variant="primary" onClick={onHandleFinalize}>
                      Finalize
                    </Button>
                  )
                ) : (
                  <Button variant="default">Finalize</Button>
                )}
                {tokenInfo.saleStatus === 1 ? (
                  isLoadingAdmin ? (
                    <span>...loading</span>
                  ) : (
                    <Button variant="outlinePrimary" onClick={onHandleCancel}>
                      Cancel Presale
                    </Button>
                  )
                ) : (
                  <Button variant="default">Cancel Presale</Button>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="status-box">
            {Object.keys(tokenInfo).length > 0 ? (
              <CountTimer
                startTime={tokenInfo.secStartTime}
                endTime={tokenInfo.secEndTime}
              />
            ) : (
              <Skeleton />
            )}
            <div className="progress">
              <ProgressBarComponent
                current={
                  Number(
                    ((tokenInfo.totalRaised / tokenInfo.softcap) * 100).toFixed(
                      0
                    )
                  ) > 100
                    ? 100
                    : Number(
                        (tokenInfo.totalRaised / tokenInfo.softcap) * 100
                      ).toFixed(0)
                }
              />
              <div className="status">
                <p className="current">
                  {tokenInfo.totalRaised} {tokenInfo.currency}
                </p>
                <p className="final">
                  {tokenInfo.softcap} {tokenInfo.currency}
                </p>
              </div>
            </div>
            <div className="buy">
              <div className="title">
                Amount (max: {tokenInfo.maxiBuy} {tokenInfo.currency}, min:{" "}
                {tokenInfo.miniBuy} {tokenInfo.currency})
              </div>
              <input
                type="number"
                className="number-input"
                value={buyAmount}
                name="buyAmount"
                onChange={handleBuyInput}
              />
              <div className="warn-item">
                <p>
                  {buyAmount && Number(buyAmount) > Number(userBalance)
                    ? "You don't have enough balance. \n"
                    : ""}
                </p>
                <p>
                  {buyAmount &&
                  (Number(buyAmount) < Number(tokenInfo.miniBuy) ||
                    Number(buyAmount) > Number(tokenInfo.maxiBuy))
                    ? "You should buy between max and min balance."
                    : ""}
                </p>
              </div>
              <div className="currentBalance">
                Your balance: {userBalance} {tokenInfo.currency}
              </div>
              {buyAmount <= Number(tokenInfo.maxiBuy) &&
              buyAmount >= Number(tokenInfo.miniBuy) &&
              moment.utc().format("X") > tokenInfo.secStartTime &&
              moment.utc().format("X") < tokenInfo.secEndTime ? (
                isLoading ? (
                  <span>...loading</span>
                ) : (
                  <Button variant="primary" onClick={onHandleBuyToken}>
                    Buy with {tokenInfo.currency}
                  </Button>
                )
              ) : (
                <Button variant="default">Buy with {tokenInfo.currency}</Button>
              )}
            </div>
          </div>
          <div className="client-box">
            {Object.keys(tokenInfo).length > 0 ? (
              <>
                <div className="row">
                  <span>Current Rate</span>
                  <span>
                    1 {tokenInfo.currency} ={" "}
                    {tokenInfo.totalRaised > 0
                      ? (
                          tokenInfo.sellingAmount / tokenInfo.totalRaised
                        ).toFixed(3) +
                        " " +
                        tokenInfo.tokenSymbol
                      : "N/A"}
                  </span>
                </div>
                <div className="row">
                  <span>Minimum Buy</span>
                  <span>
                    {tokenInfo.miniBuy} {tokenInfo.currency}
                  </span>
                </div>
                <div className="row">
                  <span>Maximum Buy</span>
                  <span>
                    {tokenInfo.maxiBuy} {tokenInfo.currency}
                  </span>
                </div>
                <div className="row">
                  <span>Contributors</span>
                  <span>{tokenInfo.totalContributor}</span>
                </div>
                <div className="row">
                  <span>Your Purchased</span>
                  <span>
                    {selfContribute} {tokenInfo.currency}
                  </span>
                </div>
              </>
            ) : (
              <Skeleton count={6} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresaleDetails;
