interface ParentProps {
  currency: string;
  handleChange: any;
  targetCurrency: string;
}

const CustomRadio = ({
  currency,
  handleChange,
  targetCurrency,
}: ParentProps) => {
  return (
    <label>
      <input
        type="radio"
        name="currency"
        value={targetCurrency}
        checked={currency === targetCurrency}
        onChange={handleChange}
      />
      {targetCurrency}
    </label>
  );
};

export default CustomRadio;
