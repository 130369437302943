// hooks
import { useWeb3React } from "@web3-react/core";

// utils
import { TokenType } from "src/utils";
import { fadeInLeft, fadeInUp } from "src/utils/keyframes";

// third party
import Reveal from "react-awesome-reveal";

// components
import Container from "src/components/Container";
import Card from "src/components/Card";

const Portfolio = () => {
  const { chainId } = useWeb3React();

  return (
    <div className="ld-portfolio" id="portfolio">
      <div className="elementor-background-overlay"></div>
      <Container className="nav-container">
        <div className="full">
          <div className="top">
            <Reveal keyframes={fadeInUp} triggerOnce>
              <div className="heading">World NO1</div>
            </Reveal>
            <Reveal keyframes={fadeInUp} delay={300} triggerOnce>
              <div className="content">
                The Easiest and Fastest way to create {TokenType[chainId]} in
                the world!
              </div>
            </Reveal>
            <Reveal keyframes={fadeInUp} delay={500} triggerOnce>
              <div className="desc">
                DIFINES has started a service that even beginners can easily
                issue {TokenType[chainId]} tokens for Binance Smart Chain.
              </div>
            </Reveal>
          </div>
          <div className="down">
            <Reveal keyframes={fadeInLeft} delay={600} triggerOnce>
              <div className="out-card">
                <Card className="secure">
                  <div className="icon">
                    <img
                      src="https://toka.b-cdn.net/wp-content/uploads/2022/04/gmv.png"
                      className="secure-img"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="heading">Trusted And Secure</div>
                  <div className="content">
                    {TokenType[chainId]} is a binance smart chain, so you can
                    rest assured.
                  </div>
                </Card>
              </div>
            </Reveal>
            <Reveal keyframes={fadeInLeft} delay={400} triggerOnce>
              <div className="out-card">
                <Card className="trading">
                  <div className="icon">
                    <img
                      src="https://toka.b-cdn.net/wp-content/uploads/2022/04/rmvlg.png"
                      className="secure-img"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="heading">At The Lowest Price</div>
                  <div className="content">
                    It can be developed with a small amount of BNB and DFS worth
                    100 $!
                  </div>
                </Card>
              </div>
            </Reveal>
            <Reveal keyframes={fadeInLeft} delay={200} triggerOnce>
              <div className="out-card">
                <Card className="auto secure">
                  <div className="icon">
                    <img
                      src="https://toka.b-cdn.net/wp-content/uploads/2022/04/vfkvf.png"
                      className="secure-img"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="heading">With One Click</div>
                  <div className="content">
                    Development can be done in just 30 seconds and with just one
                    click!
                  </div>
                </Card>
              </div>
            </Reveal>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Portfolio;
