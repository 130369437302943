// utils
import { fadeInUp } from "src/utils/keyframes";

// third party
import Reveal from "react-awesome-reveal";

// components
import Container from "src/components/Container";

const World = () => {

  return (
    <div className="ld-world">
      <div className="elementor-background-overlay"></div>
      <Container className="nav-container">
        <div className="left">
          <div className="img-group">
            <img
              src="https://toka.b-cdn.net/wp-content/uploads/2022/04/gkmbgl-1024x520.png"
              className="map"
              alt=""
              loading="lazy"
            />
            <img
              src="https://toka.b-cdn.net/wp-content/uploads/2022/04/lknfrf.png"
              className="location"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="right">
          <div className="title-group">
            <Reveal keyframes={fadeInUp} delay={100} triggerOnce>
              <div className="heading">People trust us </div>
            </Reveal>
            <Reveal keyframes={fadeInUp} delay={100} triggerOnce>
              <div className="content">
                Hundreds of millions of users around the world
              </div>
            </Reveal>
            <Reveal keyframes={fadeInUp} delay={100} triggerOnce>
              <div className="desc">
                The purpose of DIFINES is that even beginners can easily use the
                service, The purpose is to provide high quality service at a low
                price.
              </div>
              <div className="statistic">
                <div className="left">
                  <div className="num">150+</div>
                  <div className="title">Country joined </div>
                </div>
                <div className="right">
                  <div className="num">50+</div>
                  <div className="title">Services</div>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default World;
