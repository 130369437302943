import { useState, useEffect } from "react";

// firebase
import { db } from "src/utils/firebase";
import { collection, onSnapshot, DocumentData } from "firebase/firestore";

// components
import PresaleBox from "./white-sale-components/PresaleBox";

interface SaleProps {
  saleId: string;
  items: DocumentData;
}

const TokenList = () => {
  const [saleData, setSaleData] = useState<SaleProps[]>([]);

  useEffect(() => {
    onSnapshot(collection(db, "sales"), (snapshot) => {
      setSaleData(
        snapshot.docs.map((doc) => ({
          saleId: doc.id,
          items: doc.data(),
        }))
      );
    });

    return () => {
      setSaleData([]);
    };
  }, []);

  return (
    <div className="ps-list">
      <div className="container">
        <div className="section-hero">
          <h1 className="hero-title">Presale List</h1>
          <p className="hero-desc">
            View all presale list and buy what you want.
          </p>
        </div>
        <div className="section-list">
          <div className="nav-list">
            {saleData.length > 0 &&
              saleData.map((sale, idx) => (
                <PresaleBox
                  key={idx}
                  saleId={sale.saleId}
                  details={sale.items}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenList;
