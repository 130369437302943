interface ParentProps {
  value: string;
  name: string;
  type: string;
  handleChange: any;
  titleItem: string;
  warnItem?: string;
  descItem?: string;
}

const CustomInput = ({
  value,
  handleChange,
  titleItem,
  warnItem,
  descItem,
  name,
  type,
}: ParentProps) => {
  return (
    <div className="column">
      <p className="item-title">{titleItem}</p>
      <input
        type={type}
        name={name}
        className="number-input"
        value={value}
        onChange={handleChange}
      />
      <p className="warn-item">{warnItem}</p>
      <p className="item-desc">{descItem}</p>
    </div>
  );
};

export default CustomInput;
