import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Nabvar from "./Navbar";
import Sidebar from "./Sidebar";
import WhiteSaleWarn from "./WhiteSaleWarn";
import { SidebarContext } from "src/context/SidebarContext";

import "src/styles/presale.scss";

const SidebarLayout = () => {
  const { sidebarToggle } = useContext(SidebarContext);

  return (
    <>
      <Nabvar background={true} />
      <Sidebar />
      <div className={`ps-content${sidebarToggle ? "" : " ps-fullcontent"}`}>
        <Outlet />
        <WhiteSaleWarn />
      </div>
    </>
  );
};

export default SidebarLayout;
