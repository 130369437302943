import { useState } from "react";

// hooks
import { utils } from "ethers";
import { useWeb3React } from "@web3-react/core";

// utils
import {
  getContractInstance,
  ERC20TokenInfo,
  getErc20ContractInstance,
} from "src/utils";
import SaleContractABI from "src/utils/abis/WhiteSale.json";

// firebase
import { db } from "src/utils/firebase";
import { doc, updateDoc, increment } from "firebase/firestore";

// third party
import toast from "react-hot-toast";

const useSaleClient = () => {
  const { account, chainId } = useWeb3React();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function buyWithERC20(
    amount: number,
    tokenType: string,
    saleContractAddress: string
  ) {
    if (!account) {
      toast.error("Please connect your wallet");
      return;
    }

    const saleContract = await getContractInstance(
      SaleContractABI.abi,
      saleContractAddress
    );
    setIsLoading(true);
    try {
      if (tokenType === "BNB") {
        // buy token with bnb
        await saleContract.methods
          .buyTokenWithBNB()
          .send({
            from: account,
            value: utils.parseEther(Number(amount).toString()),
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", async function (receipt: any) {
            console.log("receipt: ", console.log(receipt));
          });
      } else {
        const tokenContractInstance = await getErc20ContractInstance(
          ERC20TokenInfo[tokenType][chainId]
        );
        // approve your token
        await tokenContractInstance.methods
          .approve(
            saleContractAddress,
            utils.parseUnits(Number(amount).toString(), 18)
          )
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", async function (receipt: any) {
            console.log("receipt: ", console.log(receipt));
          });

        // buy token with erc20
        await saleContract.methods
          .buyTokenWithERC20(utils.parseUnits(Number(amount).toString(), 18))
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", async function (receipt: any) {
            console.log("receipt: ", console.log(receipt));
          });
      }

      // update firebase totalRaised and totalContributor
      const docRef = doc(db, "sales", saleContractAddress);
      await updateDoc(docRef, {
        totalRaised: increment(amount),
        totalContributor: increment(1),
      })
        .then(() => {
          console.log("firestore successfully updated!");
        })
        .catch((updateErr) => {
          console.error("firestore update error: ", updateErr);
        });
      setIsLoading(false);
    } catch (err) {
      console.error("buyWithERC20 function error: ", err);
      setIsLoading(false);
    }
  }

  return {
    buyWithERC20,
    isLoading,
  };
};

export default useSaleClient;
