import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import { getProvider } from "src/utils";

const useMulticallContract = () => {
  async function getMulticallResults(callData: ContractCallContext[]) {
    let provider: any = await getProvider();
    const multicall = new Multicall({
      ethersProvider: provider,
      tryAggregate: true,
    });
    const contractCallContext: ContractCallContext[] = callData;
    const results: ContractCallResults = await multicall.call(
      contractCallContext
    );

    return results;
  }

  return {
    getMulticallResults,
  };
};

export default useMulticallContract;
