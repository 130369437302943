import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { SidebarContext } from "src/context/SidebarContext";

const Sidebar = () => {
  const [path, setPath] = useState<string>("");
  const curPath = useLocation();
  const { sidebarToggle } = useContext(SidebarContext);

  useEffect(() => {
    setPath(curPath.pathname.split("/")[2]);
  }, [curPath]);

  if (sidebarToggle) {
    return (
      <div className="ps-sidebar">
        <div className="nav-sidebar">
          <div className="main-sidebar">
            <ProSidebar>
              <Menu iconShape="square">
                <MenuItem
                  icon={
                    <img src="/statistic/assets/icons/home.svg" alt="home" loading="lazy" />
                  }
                  className={`${path === undefined ? "tab-active" : ""}`}
                >
                  <Link to="/whitesale">Home</Link>
                </MenuItem>
                <SubMenu
                  title="Presale"
                  icon={
                    <img src="/statistic/assets/icons/presale.svg" alt="home" loading="lazy" />
                  }
                >
                  <MenuItem
                    className={`${path === "create" ? "tab-active" : ""}`}
                  >
                    <Link to="/whitesale/create">Create presale</Link>
                  </MenuItem>
                  <MenuItem
                    className={`${path === "list" ? "tab-active" : ""}`}
                  >
                    <Link to="/whitesale/list">Presale list</Link>
                  </MenuItem>
                </SubMenu>
              </Menu>
            </ProSidebar>
          </div>
          <div className="bottom-sidebar">
            <div className="dfs-token">
              <img src="/statistic/assets/dfs.png" alt="dfs-token" loading="lazy" />
              <span className="token-name">Difines Token</span>
              <span className="token-symbol">DFS</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Sidebar;
