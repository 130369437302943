import { getErc20ContractInstance } from "src/utils";
import { ethers } from "ethers";

const useLoadTokeninfo = () => {
  const getTokenInfo = async (address: string) => {
    let tokenName = "";
    let totalSupply = "";
    let tokenSymbol = "";
    let tokenDecimals = "";
    let errorMsg = "";
    try {
      const tokenContract = await getErc20ContractInstance(address);
      tokenName = await tokenContract.methods.name().call();
      totalSupply = ethers.utils.formatEther(
        await tokenContract.methods.totalSupply().call()
      );
      tokenSymbol = await tokenContract.methods.symbol().call();
      tokenDecimals = await tokenContract.methods.decimals().call();

      return {
        tokenName,
        totalSupply,
        tokenSymbol,
        tokenDecimals,
        errorMsg,
      };
    } catch (err) {
      console.log(err.message);
      errorMsg = err.message;
      return {
        tokenName,
        totalSupply,
        tokenSymbol,
        tokenDecimals,
        errorMsg,
      };
    }
  };

  return {
    getTokenInfo,
  };
};

export default useLoadTokeninfo;
