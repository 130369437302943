// utils
import { fadeInUp } from "src/utils/keyframes";
import { TokenType } from "src/utils";

// hooks
import { useModalShow } from "src/hooks/useModalShow";
import { useWeb3React } from "@web3-react/core";

// third party
import { Reveal } from "react-awesome-reveal";

// components
import Button from "src/components/Button";
import Container from "src/components/Container";
import BepToken from "./BepToken";


const Hero = () => {
  const { chainId } = useWeb3React();
  const { isShow, getBepToken, closeModal } = useModalShow();

  return (
    <>
      <div className="ld-hero">
        <div className="elementor-background-overlay"></div>
        <Container className="nav-container">
          <Reveal keyframes={fadeInUp} triggerOnce>
            <div className="header">
              Binance Smart Chain {TokenType[chainId]} Token Development
            </div>
          </Reveal>
          <div className="content">
            <Reveal keyframes={fadeInUp} triggerOnce delay={500}>
              <Button
                className="download"
                onClick={getBepToken}
                variant="primary"
              >
                Get {TokenType[chainId]} Token
              </Button>
            </Reveal>
            <div className="rotate-widget">
              <img
                width="1016"
                src="https://toka.b-cdn.net/wp-content/uploads/2022/04/nrgvkg-1016x1024.png"
                className="attachment-large size-large"
                alt="rotateImage"
                loading="lazy"
              />
            </div>
            <div className="phone">
              <img
                width="1024"
                loading="lazy"
                src="/statistic/assets/landing/binancelogo.png"
                alt="phoneImage"
              />
            </div>
          </div>
        </Container>
      </div>
      <BepToken isShow={isShow} onClose={closeModal} />
    </>
  );
};

export default Hero;
