// firebase
import { DocumentData } from "firebase/firestore";

// components
import Card from "src/components/Card";
import Button from "src/components/Button";
import ProgressBarComponent from "src/components/ProgressBar";
import SocialIcon from "./SocialIcon";
import CountTimer from "./CountTimer";

interface ParentProps {
  details: DocumentData;
  saleId: string;
}

const PresaleBox = ({ details, saleId }: ParentProps) => {
  return (
    <Card className="card-component">
      <div className="card-container">
        <div className="header">
          <div className="logo">
            <img
              src={
                details.logoUrl
                  ? details.logoUrl
                  : "/statistic/assets/sample_token.png"
              }
              alt="tokenLogo"
            />
          </div>
          <CountTimer
            startTime={details.secStartTime}
            endTime={details.secEndTime}
          />
        </div>
        <div className="name">
          <div className="tokenName">{details.tokenName}</div>
          <div className="presaleInfoUrl">
            {details.website && (
              <SocialIcon linkUrl={details.website} linkType="website" />
            )}
            {details.facebook && (
              <SocialIcon linkUrl={details.facebook} linkType="facebook" />
            )}
            {details.twitter && (
              <SocialIcon linkUrl={details.twitter} linkType="twitter" />
            )}
            {details.github && (
              <SocialIcon linkUrl={details.github} linkType="github" />
            )}
            {details.telegram && (
              <SocialIcon linkUrl={details.telegram} linkType="telegram" />
            )}
            {details.instagram && (
              <SocialIcon linkUrl={details.instagram} linkType="instagram" />
            )}
            {details.discord && (
              <SocialIcon linkUrl={details.discord} linkType="discord" />
            )}
          </div>
        </div>
        <div className="cap">
          <div className="cap-title">SaleAmount / Softcap</div>
          <div className="cap-value">
            {details.sellingAmount} {details.tokenSymbol} - {details.softcap}{" "}
            {details.currency}
          </div>
        </div>
        <div className="progress">
          <div className="title">
            <span>
              Progress:{" "}
              {((details.totalRaised / details.softcap) * 100).toFixed(2)}%
            </span>
            <span>Contributors: {details.totalContributor}</span>
          </div>
          <div className="bar">
            <ProgressBarComponent
              current={
                Number(
                  ((details.totalRaised / details.softcap) * 100).toFixed(0)
                ) > 100
                  ? 100
                  : Number(
                      ((details.totalRaised / details.softcap) * 100).toFixed(0)
                    )
              }
            />
          </div>
          <div className="value">
            <span className="currentValue">
              {details.totalRaised} {details.currency}
            </span>
            <span className="finalValue">
              {details.softcap} {details.currency}
            </span>
          </div>
        </div>
        <div className="footer">
          <Button
            variant="outlinePrimary"
            isLink={true}
            href={`../whitesale/list/${saleId}`}
          >
            View
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default PresaleBox;
