import { useRoutes } from "react-router-dom";
import { useEagerConnect } from "src/hooks/useEagerConnect";
import MainLayout from "./pages/layouts/MainLayout";
import SidebarLayout from "./pages/layouts/SidebarLayout";
import Landing from "./pages/white-creator/Landing";
import TokenHome from "./pages/white-sale/TokenHome";
import TokenCreate from "./pages/white-sale/TokenCreate";
import TokenList from "./pages/white-sale/TokenList";
import PresaleDetails from "./pages/white-sale/PresaleDetails";

function App() {
  useEagerConnect();

  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Landing />,
        },
      ],
    },
    {
      element: <SidebarLayout />,
      children: [
        {
          path: "/whitesale",
          element: <TokenHome />,
        },
        {
          path: "/whitesale/create",
          element: <TokenCreate />,
        },
        {
          path: "/whitesale/list",
          element: <TokenList />,
        },
        {
          path: "/whitesale/list/:address",
          element: <PresaleDetails />,
        },
      ],
    },
  ]);

  return routes;
}

export default App;
