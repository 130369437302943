import { useState } from "react";
import { useNavigate } from "react-router-dom";

// third party
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/colors/red.css";
import { Widget } from "@uploadcare/react-widget";
import effects from "uploadcare-widget-tab-effects/react";
import moment from "moment";

// hooks
import { utils, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import useLoadTokenInfo from "src/hooks/useLoadTokenInfo";
import useSaleAdmin from "src/hooks/useSaleAdmin";
import { getErc20ContractInstance } from "src/utils";

// components
import Button from "src/components/Button";
import Card from "src/components/Card";
import Modal from "src/components/Modal";
import Loader from "src/components/Loader";
import PreviewToken from "./white-sale-components/PreviewToken";
import CustomInput from "./white-sale-components/CustomInput";
import CustomRadio from "./white-sale-components/CustomRadio";

interface TokenInfoTypes {
  saleAddress: string;
  tokenAddress: string;
  tokenName: string;
  tokenSymbol: string;
  tokenDecimals: string;
  totalSupply: string;
  currency: string;
  sellingAmount: string;
  softcap: string;
  hardcap: string;
  miniBuy: string;
  maxiBuy: string;
  startTime: DateObject;
  secStartTime: string;
  endTime: DateObject;
  secEndTime: string;
  logoUrl: string;
  website: string;
  facebook?: string;
  twitter?: string;
  github?: string;
  telegram?: string;
  instagram?: string;
  discord?: string;
  description?: string;
}

const TokenCreate = () => {
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const initialTokenInfo = {
    saleAddress: "",
    tokenAddress: "",
    tokenName: "",
    tokenSymbol: "",
    tokenDecimals: "",
    totalSupply: "",
    currency: "BNB",
    sellingAmount: "",
    softcap: "",
    hardcap: "",
    miniBuy: "",
    maxiBuy: "",
    startTime: null,
    secStartTime: "",
    endTime: null,
    secEndTime: "",
    logoUrl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    description: "",
  };
  const { getTokenInfo } = useLoadTokenInfo();
  const { createSaleByAdmin, startSaleByAdmin, isLoadingAdmin } =
    useSaleAdmin();
  const [loadTokenStatus, setLoadTokenStatus] = useState<string>("none");
  const [tokenInfo, setTokenInfo] = useState<TokenInfoTypes>(initialTokenInfo);
  const [tokenAddressErrorMsg, setTokenAddressErrorMsg] = useState<string>("");
  const [availableTokenAmount, setAvailableTokenAmount] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [previewStatus, setPreviewStatus] = useState<boolean>(false);
  const TokenInfoObject = {
    none: <></>,
    loading: <div className="column">...loading</div>,
    loaded: (
      <div className="column">
        <div className="row">
          <span>Name</span>
          <span>{tokenInfo.tokenName}</span>
        </div>
        <div className="row">
          <span>Symbol</span>
          <span>{tokenInfo.tokenSymbol}</span>
        </div>
        <div className="row">
          <span>Decimals</span>
          <span>{tokenInfo.tokenDecimals}</span>
        </div>
        <div className="row">
          <span>Total Supply</span>
          <span>{tokenInfo.totalSupply}</span>
        </div>
      </div>
    ),
  };

  async function onLoadToken(evt: { target: { name: any; value: any } }) {
    setLoadTokenStatus("loading");
    const { name, value } = evt.target;
    const { tokenName, tokenDecimals, tokenSymbol, totalSupply, errorMsg } =
      await getTokenInfo(value);
    setTokenAddressErrorMsg(errorMsg);
    if (
      utils.isAddress(value) &&
      tokenName &&
      tokenDecimals &&
      tokenSymbol &&
      totalSupply
    ) {
      const tokenContractInstance = await getErc20ContractInstance(value);
      const tokenInWallet = ethers.utils.formatEther(
        await tokenContractInstance.methods.balanceOf(account).call()
      );
      setTokenInfo({
        ...tokenInfo,
        [name]: value,
        tokenName,
        totalSupply,
        tokenSymbol,
        tokenDecimals,
      });
      setAvailableTokenAmount(tokenInWallet);
      setLoadTokenStatus("loaded");
    } else {
      console.error("token address is not valid");
      setLoadTokenStatus("none");
      setTokenInfo({
        ...tokenInfo,
        [name]: value,
      });
    }
  }
  function changeTokenInfo(evt: { target: { name: any; value: any } }) {
    const { name, value } = evt.target;
    setTokenInfo({
      ...tokenInfo,
      [name]: value,
    });
  }
  function changeDate(type: string, datetime: DateObject) {
    let toSeconds = moment
      .utc(datetime.toUTC().format("YYYY-MM-DD HH:mm:ss"))
      .format("X");
    if (type === "starttime")
      setTokenInfo({
        ...tokenInfo,
        startTime: datetime.toUTC(),
        secStartTime: toSeconds.toString(),
      });
    else
      setTokenInfo({
        ...tokenInfo,
        endTime: datetime.toUTC(),
        secEndTime: toSeconds.toString(),
      });
  }
  function uploadCareChange(url: string) {
    setTokenInfo({
      ...tokenInfo,
      logoUrl: url,
    });
  }

  function validateTokenInfo() {
    return (
      !tokenInfo.tokenAddress ||
      tokenAddressErrorMsg ||
      Number(tokenInfo.miniBuy) === 0 ||
      Number(tokenInfo.maxiBuy) === 0 ||
      Number(tokenInfo.miniBuy) > Number(tokenInfo.maxiBuy) ||
      Number(tokenInfo.softcap) === 0 ||
      Number(tokenInfo.hardcap) === 0 ||
      Number(tokenInfo.hardcap) < Number(tokenInfo.softcap) ||
      Number(availableTokenAmount) === 0 ||
      !tokenInfo.startTime ||
      !tokenInfo.endTime ||
      tokenInfo.secEndTime <= tokenInfo.secStartTime ||
      Number(availableTokenAmount) < Number(tokenInfo.sellingAmount)
    );
  }
  function previewResult() {
    setPreviewStatus(true);
    if (validateTokenInfo()) return;
    setIsOpen(true);
  }
  function closeModal() {
    if (tokenInfo.saleAddress) return;
    setIsOpen(false);
  }
  async function createSale() {
    await createSaleByAdmin(setTokenInfo, tokenInfo);
  }
  async function startSale() {
    await startSaleByAdmin(tokenInfo);
    setIsOpen(false);
    navigate(`/whitesale/list/${tokenInfo.saleAddress}`);
  }

  return (
    <div className="ps-create">
      <div className="container">
        <div className="section-hero">
          <h1 className="hero-title">Create Launchpad</h1>
          <p className="hero-desc">
            Enter the lanuchpad information that you want to raise, that should
            be enter all details about your sale.
          </p>
        </div>
        <div className="section-form">
          <Card className="card-component">
            <div className="card-container">
              <div className="create-info">
                <p>Sale creation fee is 1 BNB</p>
              </div>
              <div className="token-info nav-section">
                <CustomInput
                  titleItem="Token address *"
                  value={tokenInfo.tokenAddress}
                  name="tokenAddress"
                  type="text"
                  handleChange={onLoadToken}
                  warnItem={`
                    ${tokenAddressErrorMsg || ""}
                    ${
                      previewStatus && !tokenInfo.tokenAddress
                        ? "Token Address can't be empty"
                        : ""
                    }`}
                />
                {TokenInfoObject[loadTokenStatus]}
                <div className="column">
                  <p className="item-title">Accept Currency</p>
                  <div className="grid">
                    <CustomRadio
                      currency={tokenInfo.currency}
                      targetCurrency="BNB"
                      handleChange={changeTokenInfo}
                    />
                    <CustomRadio
                      currency={tokenInfo.currency}
                      targetCurrency="BUSD"
                      handleChange={changeTokenInfo}
                    />
                    <CustomRadio
                      currency={tokenInfo.currency}
                      targetCurrency="USDC"
                      handleChange={changeTokenInfo}
                    />
                    <CustomRadio
                      currency={tokenInfo.currency}
                      targetCurrency="USDT"
                      handleChange={changeTokenInfo}
                    />
                  </div>
                  <p className="item-desc">
                    Users will pay with {tokenInfo.currency} for your token
                  </p>
                </div>
                <CustomInput
                  titleItem={`Your Token Amount For Sale * (Available in your wallet: ${availableTokenAmount})`}
                  value={tokenInfo.sellingAmount}
                  name="sellingAmount"
                  type="number"
                  handleChange={changeTokenInfo}
                  warnItem={`${
                    previewStatus && Number(tokenInfo.sellingAmount) === 0
                      ? "Selling amount can't be 0"
                      : ""
                  }
                  ${
                    tokenInfo.tokenAddress &&
                    (Number(availableTokenAmount) === 0 ||
                      Number(availableTokenAmount) <
                        Number(tokenInfo.sellingAmount))
                      ? "You don't have enough token for sale"
                      : ""
                  }
                  ${!account ? "Connect your wallet" : ""}
                  `}
                  descItem={`You will sell
                    ${tokenInfo.sellingAmount ? tokenInfo.sellingAmount : 0}
                    ${tokenInfo.tokenSymbol}`}
                />
              </div>
              <div className="addtional-info nav-section">
                <div className="row">
                  <CustomInput
                    titleItem={`Softcap (${tokenInfo.currency}) *`}
                    value={tokenInfo.softcap}
                    name="softcap"
                    type="number"
                    handleChange={changeTokenInfo}
                    warnItem={`${
                      previewStatus && Number(tokenInfo.softcap) === 0
                        ? "Softcap can't be 0"
                        : ""
                    }
                      ${
                        tokenInfo.hardcap &&
                        tokenInfo.softcap &&
                        Number(tokenInfo.softcap) > Number(tokenInfo.hardcap)
                          ? "Softcap should bigger than Hardcap"
                          : ""
                      }`}
                  />
                  <CustomInput
                    titleItem={`Hardcap (${tokenInfo.currency}) *`}
                    value={tokenInfo.hardcap}
                    name="hardcap"
                    type="number"
                    handleChange={changeTokenInfo}
                    warnItem={`${
                      previewStatus && Number(tokenInfo.hardcap) === 0
                        ? "Hardcap can't be 0"
                        : ""
                    }
                      ${
                        tokenInfo.hardcap &&
                        tokenInfo.softcap &&
                        Number(tokenInfo.softcap) > Number(tokenInfo.hardcap)
                          ? "Softcap should bigger than Hardcap"
                          : ""
                      }`}
                  />
                </div>
                <div className="row">
                  <CustomInput
                    titleItem={`Minimum buy (${tokenInfo.currency}) *`}
                    value={tokenInfo.miniBuy}
                    name="miniBuy"
                    type="number"
                    handleChange={changeTokenInfo}
                    warnItem={`${
                      previewStatus && Number(tokenInfo.miniBuy) === 0
                        ? "Minumum buy amount can't be 0"
                        : ""
                    }
                      ${
                        tokenInfo.miniBuy &&
                        tokenInfo.maxiBuy &&
                        Number(tokenInfo.miniBuy) > Number(tokenInfo.maxiBuy)
                          ? "Maximum buy should bigger than Minimum buy"
                          : ""
                      }`}
                    descItem={`
                      If I spend 1 ${tokenInfo.currency} how many tokens will I
                      receive?
                      `}
                  />
                  <CustomInput
                    titleItem={`Maximum buy (${tokenInfo.currency}) *`}
                    value={tokenInfo.maxiBuy}
                    name="maxiBuy"
                    type="number"
                    handleChange={changeTokenInfo}
                    warnItem={`${
                      previewStatus && Number(tokenInfo.maxiBuy) === 0
                        ? "Maximum buy amount can't be 0"
                        : ""
                    }
                      ${
                        tokenInfo.miniBuy &&
                        tokenInfo.maxiBuy &&
                        Number(tokenInfo.miniBuy) > Number(tokenInfo.maxiBuy)
                          ? "Maximum buy should bigger than Minimum buy"
                          : ""
                      }`}
                    descItem={`
                      If I spend 1 ${tokenInfo.currency} how many tokens will I
                      receive?
                      `}
                  />
                </div>
                <div className="row">
                  <div className="column">
                    <p className="item-title">Start time (UTC) *</p>
                    <DatePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      minDate={new Date().setDate(new DateObject().toUTC().day)}
                      plugins={[<TimePicker style={{ minWidth: "100px" }} />]}
                      value={tokenInfo.startTime}
                      onChange={(evt: DateObject) =>
                        changeDate("starttime", evt)
                      }
                      className="text-input bg-dark red"
                    />
                    <p className="warn-item">
                      {previewStatus && !tokenInfo.startTime
                        ? "Start time can't be empty"
                        : ""}
                      {tokenInfo.startTime &&
                      tokenInfo.endTime &&
                      tokenInfo.secEndTime <= tokenInfo.secStartTime
                        ? "End time should bigger than Start time"
                        : ""}
                    </p>
                    <p className="item-desc"></p>
                  </div>
                  <div className="column">
                    <p className="item-title">End time (UTC) *</p>
                    <DatePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      minDate={new Date().setDate(
                        new DateObject(tokenInfo.startTime).toUTC().day
                      )}
                      plugins={[<TimePicker style={{ minWidth: "100px" }} />]}
                      value={tokenInfo.endTime}
                      onChange={(evt: DateObject) => changeDate("endtime", evt)}
                      className="text-input bg-dark red"
                    />
                    <p className="warn-item">
                      {previewStatus && !tokenInfo.endTime
                        ? "End time can't be empty"
                        : ""}
                      {tokenInfo.startTime &&
                      tokenInfo.endTime &&
                      tokenInfo.secEndTime <= tokenInfo.secStartTime
                        ? "End time should bigger than Start time"
                        : ""}
                    </p>
                    <p className="item-desc"></p>
                  </div>
                </div>
              </div>
              <div className="more-info nav-section">
                <div className="row">
                  <div className="column">
                    <p className="item-title">Logo url</p>
                    <input
                      type="text"
                      name="logoUrl"
                      className="text-input"
                      value={tokenInfo.logoUrl}
                      onChange={changeTokenInfo}
                      disabled
                    />
                    <Widget
                      publicKey={process.env.REACT_APP_UPLOADCARE_PUBLICK_KEY}
                      previewStep={true}
                      customTabs={{ preview: effects }}
                      onChange={(info) => uploadCareChange(info.cdnUrl)}
                    />
                    <p className="item-desc">
                      Your image will be uploaded by uploadcare widget.
                    </p>
                  </div>
                  <CustomInput
                    titleItem="Website"
                    value={tokenInfo.website}
                    name="website"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                </div>
                <div className="row">
                  <CustomInput
                    titleItem="Facebook"
                    value={tokenInfo.facebook}
                    name="facebook"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                  <CustomInput
                    titleItem="Twitter"
                    value={tokenInfo.twitter}
                    name="twitter"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                </div>
                <div className="row">
                  <CustomInput
                    titleItem="Github"
                    value={tokenInfo.github}
                    name="github"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                  <CustomInput
                    titleItem="Telegram"
                    value={tokenInfo.telegram}
                    name="telegram"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                </div>
                <div className="row">
                  <CustomInput
                    titleItem="Instagram"
                    value={tokenInfo.instagram}
                    name="instagram"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                  <CustomInput
                    titleItem="Discord"
                    value={tokenInfo.discord}
                    name="discord"
                    type="text"
                    handleChange={changeTokenInfo}
                  />
                </div>
                <div className="column">
                  <p className="item-title">Description</p>
                  <textarea
                    name="description"
                    className="text-input"
                    rows={7}
                    placeholder="Ex: This token is designed once it is..."
                    value={tokenInfo.description}
                    onChange={changeTokenInfo}
                  ></textarea>
                </div>
              </div>
              <div className="submit-btn nav-section">
                <Button variant="primary" onClick={previewResult}>
                  Preview
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={closeModal} className="white-modal">
        <div
          className={`overlay ${
            isLoadingAdmin ? "show-overlay" : "hide-overlay"
          }`}
        >
          <Loader />
        </div>
        <h1 className="heading">Preview Presale Info</h1>
        {isOpen ? <PreviewToken details={tokenInfo} /> : <></>}
        <div className="footer">
          {tokenInfo.saleAddress ? (
            <Button variant="primary" onClick={startSale}>
              Start Sale
            </Button>
          ) : (
            <>
              <Button variant="primary" onClick={createSale}>
                Create
              </Button>
              <Button variant="outlinePrimary" onClick={closeModal}>
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TokenCreate;
