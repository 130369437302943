import { Link } from "react-router-dom";

// utils
import { fadeInLeft, fadeInRight, fadeInUp } from "src/utils/keyframes";
import { TokenType } from "src/utils";

// hooks
import { useModalShow } from "src/hooks/useModalShow";
import { useWeb3React } from "@web3-react/core";

// third party
import { Reveal } from "react-awesome-reveal";

// components
import Container from "src/components/Container";
import BepToken from "./BepToken";

const About = () => {
  const { chainId } = useWeb3React();
  const { isShow, getBepToken, closeModal } = useModalShow();

  return (
    <>
      <div className="ld-about" id="about">
        <div className="elementor-background-overlay"></div>
        <Container className="nav-container">
          <div className="img-group">
            <Reveal keyframes={fadeInLeft} triggerOnce>
              <div className="line-back">
                <img
                  width="400"
                  src="https://toka.b-cdn.net/wp-content/uploads/2022/04/krjfr.png"
                  className="star"
                  alt=""
                  loading="lazy"
                  sizes="(max-width: 400px) 100vw, 400px"
                />
                <img
                  src="/statistic/assets/landing/coin.png"
                  className="man"
                  alt=""
                  loading="lazy"
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </div>
            </Reveal>
          </div>
          <div className="description">
            <Reveal keyframes={fadeInRight} triggerOnce>
              <div className="nav-desc">
                <div className="heading">ABOUT APP</div>
                <div className="content">
                  Create a {TokenType[chainId]} Token with BINANCE Smart Chain
                </div>
                <div className="desc">
                  With DIFINES you can create your own {TokenType[chainId]} token for 100 $ in
                  just 30 seconds.
                </div>
                <div className="link">
                  <Reveal keyframes={fadeInUp} delay={500} triggerOnce>
                    <Link to="#" onClick={getBepToken}>
                      <span className="title">GET {TokenType[chainId]} TOKEN</span>
                      <span className="icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-long-arrow-alt-right"
                        ></i>
                      </span>
                    </Link>
                  </Reveal>
                </div>
              </div>
            </Reveal>
          </div>
        </Container>
      </div>
      <BepToken isShow={isShow} onClose={closeModal} />
    </>
  );
};

export default About;
