// third party
import moment from "moment";

// hooks
import { useWeb3React } from "@web3-react/core";
import { BlockchainNetwork } from "src/utils";

// components
import SocialIcon from "./SocialIcon";

const PreviewToken = ({ details }) => {
  const { chainId } = useWeb3React();

  function openAddress(address: string) {
    window.open(BlockchainNetwork[chainId]["url"] + address);
  }

  return (
    <div className="content">
      <div className="more-view">
        <div className="tokenLogo">
          <img
            src={
              details.logoUrl
                ? details.logoUrl
                : "/statistic/assets/sample_token.png"
            }
            alt="tokenLogo"
            loading="lazy"
          />
        </div>
        <div className="tokenMore">
          <div className="tokenName">{details.tokenName}</div>
          <div className="tokenLinks">
            {details.website && (
              <SocialIcon linkUrl={details.website} linkType="website" />
            )}
            {details.facebook && (
              <SocialIcon linkUrl={details.facebook} linkType="facebook" />
            )}
            {details.twitter && (
              <SocialIcon linkUrl={details.twitter} linkType="twitter" />
            )}
            {details.github && (
              <SocialIcon linkUrl={details.github} linkType="github" />
            )}
            {details.telegram && (
              <SocialIcon linkUrl={details.telegram} linkType="telegram" />
            )}
            {details.instagram && (
              <SocialIcon linkUrl={details.instagram} linkType="instagram" />
            )}
            {details.discord && (
              <SocialIcon linkUrl={details.discord} linkType="discord" />
            )}
          </div>
          <div className="tokenDesc">{details.description}</div>
        </div>
      </div>
      <div className="column">
        <div className="row">
          <span>Token Name</span>
          <span>{details.tokenName}</span>
        </div>
        <div className="row">
          <span>Token Symbol</span>
          <span>{details.tokenSymbol}</span>
        </div>
        <div className="row">
          <span>Token Decimal</span>
          <span>{details.tokenDecimals}</span>
        </div>
        <div className="row">
          <span>Token Address</span>
          <span
            className="address"
            onClick={() => openAddress(details.tokenAddress)}
          >
            {details.tokenAddress}
          </span>
        </div>
        <div className="row">
          <span>Total Supply</span>
          <span>
            {details.totalSupply} {details.tokenSymbol}
          </span>
        </div>
        <div className="row">
          <span>Token Amount For Sale</span>
          <span>
            {details.sellingAmount} {details.tokenSymbol}
          </span>
        </div>
        <div className="row">
          <span>Accept Currency</span>
          <span>{details.currency}</span>
        </div>
        <div className="row">
          <span>Soft Cap</span>
          <span>
            {details.softcap} {details.currency}
          </span>
        </div>
        <div className="row">
          <span>Hard Cap</span>
          <span>
            {details.hardcap} {details.currency}
          </span>
        </div>
        <div className="row">
          <span>Sale Start Time</span>
          <span>
            {moment.unix(details.secStartTime).utc().format("YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
        <div className="row">
          <span>Sale End Time</span>
          <span>
            {moment.unix(details.secEndTime).utc().format("YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
        <div className="row">
          <span>Sale Address</span>
          <span
            className="address"
            onClick={() => openAddress(details.saleAddress)}
          >
            {details.saleAddress
              ? details.saleAddress
              : "Please click Create button"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PreviewToken;
