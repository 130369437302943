interface ParentProps {
  linkUrl: string;
  linkType: string;
}

const SocialIcon = ({ linkUrl, linkType }: ParentProps) => {
  return (
    <div className="social-icon">
      <a href={linkUrl} target="_blank" rel="noreferrer">
        <img
          src={`/statistic/assets/logo/${linkType}.svg`}
          alt={`${linkType}-logo`}
        />
      </a>
    </div>
  );
};

export default SocialIcon;
