import { useState, useEffect } from "react";

// third party
import moment from "moment";

// utils
import { calculateTimeLeft } from "src/utils";

interface CountTimeProps {
  leftDays: string;
  leftHours: string;
  leftMinutes: string;
  leftSeconds: string;
  end: string;
}

const CountTimer = ({ startTime, endTime }: any) => {
  const [countTime, setCountTime] = useState<CountTimeProps>({
    leftDays: "",
    leftHours: "",
    leftMinutes: "",
    leftSeconds: "",
    end: "",
  });

  useEffect(() => {
    let leftDays = "";
    let leftHours = "";
    let leftMinutes = "";
    let leftSeconds = "";
    let targetTime = "";
    let interval: NodeJS.Timer;
    let status = "";

    interval = setInterval(() => {
      if (Number(moment.utc().format("X")) < Number(startTime)) {
        targetTime = startTime;
        status = "before";
      } else if (
        Number(moment.utc().format("X")) >= Number(startTime) &&
        Number(moment.utc().format("X")) < Number(endTime)
      ) {
        targetTime = endTime;
        status = "going";
      } else {
        targetTime = moment.utc().format("X");
        status = "end";
      }
      leftDays =
        calculateTimeLeft(targetTime).leftDays < 10
          ? "0" + calculateTimeLeft(targetTime).leftDays.toString()
          : calculateTimeLeft(targetTime).leftDays.toString();
      leftHours =
        calculateTimeLeft(targetTime).leftHours < 10
          ? "0" + calculateTimeLeft(targetTime).leftHours.toString()
          : calculateTimeLeft(targetTime).leftHours.toString();
      leftMinutes =
        calculateTimeLeft(targetTime).leftMinutus < 10
          ? "0" + calculateTimeLeft(targetTime).leftMinutus.toString()
          : calculateTimeLeft(targetTime).leftMinutus.toString();
      leftSeconds =
        calculateTimeLeft(targetTime).leftSeconds < 10
          ? "0" + calculateTimeLeft(targetTime).leftSeconds.toString()
          : calculateTimeLeft(targetTime).leftSeconds.toString();
          
      setCountTime({
        leftDays,
        leftHours,
        leftMinutes,
        leftSeconds,
        end: status,
      });
    }, 1000);

    if (countTime.end === "end") {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <div className="time">
        <div className="sale-status">
          {countTime.end === "before" && "Sale Starts In:"}
          {countTime.end === "going" && "Sale Ends In:"}
          {countTime.end === "end" && "Sale:"}
        </div>
        <div className="time-box">
          {countTime.end === "end" ? (
            <div>End</div>
          ) : (
            <>
              <div className="day">{countTime.leftDays}</div>
              <div className="hour">{countTime.leftHours}</div>
              <div className="minute">{countTime.leftMinutes}</div>
              <div className="second">{countTime.leftSeconds}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CountTimer;
