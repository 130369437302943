import { useState } from "react";

// hooks
import Web3 from "web3";
import { utils } from "ethers";
import { AbiItem } from "web3-utils";
import { useWeb3React } from "@web3-react/core";

// utils
import {
  getContractInstance,
  ERC20TokenInfo,
  getErc20ContractInstance,
} from "src/utils";
import SaleContractABI from "src/utils/abis/WhiteSale.json";

// third party
import toast from "react-hot-toast";

// firebase
import { db } from "src/utils/firebase";
import { updateDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";

const useSaleAdmin = () => {
  const { account, chainId } = useWeb3React();
  const [isLoadingAdmin, setIsLoadingAdmin] = useState<boolean>(false);

  async function createSaleByAdmin(setTokenInfo, tokenInfo) {
    if (!account) {
      toast.error("Connect your metamask to create sale");
      return;
    }
    setIsLoadingAdmin(true);
    // deploy whitesale contract
    let web3 = new Web3(Web3.givenProvider);
    let saleContractInstance = new web3.eth.Contract(
      SaleContractABI.abi as AbiItem[]
    );
    try {
      await saleContractInstance
        .deploy({
          data: SaleContractABI.bytecode,
          arguments: [],
        })
        .send(
          {
            from: account,
          },
          function (error, transactionHash) {
            if (error) {
              console.error(error);
              return;
            }
            console.log("Transaction Hash :", transactionHash);
          }
        )
        .on("confirmation", function () {
          return;
        })
        .then(async function (newContractInstance) {
          if (!newContractInstance.options.address) {
            console.log(newContractInstance);
            return;
          }
          console.log(
            "Deployed Contract Address : ",
            newContractInstance.options.address
          );
          setTokenInfo({
            ...tokenInfo,
            saleAddress: newContractInstance.options.address,
          });
          const { startTime, endTime, saleAddress, ...restTokenInfo } =
            tokenInfo;
          const docRef = doc(db, "sales", newContractInstance.options.address);
          await setDoc(docRef, {
            ...restTokenInfo,
            totalContributor: 0,
            totalRaised: 0,
            saleAddress: newContractInstance.options.address,
            ownerAddress: account,
            saleStatus: 0, // 0: not started, 1: started, 2: finished
            createdat: serverTimestamp(),
          })
            .then(() => {
              console.log("new sale successfully saved!");
            })
            .catch((saveErr) => {
              console.error("firestore saved error: ", saveErr);
            });
          setIsLoadingAdmin(false);
        })
        .catch(function (error) {
          console.error(error);
          setIsLoadingAdmin(false);
        });
    } catch (err) {
      console.error("while creating sale, error occured: ", err);
      setIsLoadingAdmin(false);
    }
  }

  async function startSaleByAdmin(tokenInfo) {
    setIsLoadingAdmin(true);
    try {
      // approve selling token amount
      const tokenContractInstance = await getErc20ContractInstance(
        tokenInfo.tokenAddress
      );
      await tokenContractInstance.methods
        .approve(
          tokenInfo.saleAddress,
          utils.parseUnits(
            Number(tokenInfo.sellingAmount).toString(),
            tokenInfo.tokenDecimals
          )
        )
        .send({
          from: account,
        })
        .on("confirmation", function (confirmationNumber, receipt) {})
        .on("receipt", function (receipt: any) {});

      // startICO contract calling
      const saleContractInstance = await getContractInstance(
        SaleContractABI.abi,
        tokenInfo.saleAddress
      );
      if (tokenInfo.currency === "BNB") {
        await saleContractInstance.methods
          .startICOWithBNB(
            tokenInfo.tokenAddress,
            tokenInfo.secStartTime,
            tokenInfo.secEndTime,
            utils.parseEther(tokenInfo.miniBuy),
            utils.parseEther(tokenInfo.maxiBuy),
            utils.parseUnits(tokenInfo.sellingAmount, 18),
            utils.parseEther(tokenInfo.softcap),
            utils.parseEther(tokenInfo.hardcap)
          )
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", function (receipt: any) {})
          .catch(function (error: any) {
            console.error(error);
            setIsLoadingAdmin(false);
          });
      } else {
        await saleContractInstance.methods
          .startICOWithERC20(
            tokenInfo.tokenAddress,
            ERC20TokenInfo[tokenInfo.currency][chainId],
            tokenInfo.secStartTime,
            tokenInfo.secEndTime,
            utils.parseUnits(Number(tokenInfo.miniBuy).toString(), 18),
            utils.parseUnits(Number(tokenInfo.maxiBuy).toString(), 18),
            utils.parseUnits(
              Number(tokenInfo.sellingAmount).toString(),
              tokenInfo.tokenDecimals
            ),
            utils.parseUnits(Number(tokenInfo.softcap).toString(), 18),
            utils.parseUnits(Number(tokenInfo.hardcap).toString(), 18)
          )
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", function (receipt: any) {})
          .catch(function (error: any) {
            console.error(error);
            setIsLoadingAdmin(false);
          });
      }

      const docRef = doc(db, "sales", tokenInfo.saleAddress);
      await updateDoc(docRef, {
        saleStatus: 1,
      })
        .then(() => {
          console.log("firestore successfully updated!");
        })
        .catch((updateErr) => {
          console.error("firestore update error: ", updateErr);
          setIsLoadingAdmin(false);
        });
      setIsLoadingAdmin(false);
    } catch (err) {
      console.error("while staring sale, error occured: ", err);
      setIsLoadingAdmin(false);
    }
  }

  async function finishSaleWithERC20(
    saleContractAddress: string,
    currency: string
  ) {
    const saleContract = await getContractInstance(
      SaleContractABI.abi,
      saleContractAddress
    );
    setIsLoadingAdmin(true);
    try {
      if (currency === "BNB") {
        await saleContract.methods
          .finishSaleWithBNB()
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", function (receipt: any) {
            console.log("reciept: ", receipt);
          });
      } else {
        await saleContract.methods
          .finishSaleWithERC20()
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", function (receipt: any) {
            console.log("reciept: ", receipt);
          });
      }

      // update firebase saleStatus
      const docRef = doc(db, "sales", saleContractAddress);
      await updateDoc(docRef, {
        saleStatus: 2,
      })
        .then(() => {
          console.log("firestore successfully updated!");
        })
        .catch((updateErr) => {
          console.error("firestore update error: ", updateErr);
        });
      setIsLoadingAdmin(false);
    } catch (err) {
      console.error("finishSaleWithERC20 function error: ", err);
      setIsLoadingAdmin(false);
    }
  }

  async function cancelSaleWithERC20(
    saleContractAddress: string,
    currency: string
  ) {
    const saleContract = await getContractInstance(
      SaleContractABI.abi,
      saleContractAddress
    );
    setIsLoadingAdmin(true);
    try {
      if (currency === "BNB") {
        await saleContract.methods
          .cancelSaleWithBNB()
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", async function (receipt: any) {
            console.log("receipt: ", receipt);
          });
      } else {
        await saleContract.methods
          .cancelSaleWithERC20()
          .send({
            from: account,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", async function (receipt: any) {
            console.log("receipt: ", receipt);
          });
      }

      // update firebase saleStatus
      const docRef = doc(db, "sales", saleContractAddress);
      await updateDoc(docRef, {
        saleStatus: 2,
      })
        .then(() => {
          console.log("firestore successfully updated!");
        })
        .catch((updateErr) => {
          console.error("firestore update error: ", updateErr);
        });
      setIsLoadingAdmin(false);
    } catch (err) {
      console.error("finishSaleWithERC20 function error: ", err);
      setIsLoadingAdmin(false);
    }
  }

  return {
    isLoadingAdmin,
    setIsLoadingAdmin,
    createSaleByAdmin,
    startSaleByAdmin,
    finishSaleWithERC20,
    cancelSaleWithERC20,
  };
};

export default useSaleAdmin;
